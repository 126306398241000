import React, { Component } from 'react'
import AboutUsS from './AboutUsS.css'
export default class AboutUs extends Component {
  render() {
    return (
      <div className='about_us'>
          <p>AEIX group - молодая, надёжная компания, которая <br/>
           оказывает помощь бизнесу любой категории от малого <br/>
           и среднего до большого и уже стабильного. Наша <br/>
           фирма поможет вам обрести увереность в завтрашнем <br/>
           дне, т.к. о ваших клиентах всегда может позаботиться <br/>
           наш голосовой бот, а поток постоянных клиентов <br/>
           обеспечит услуга лидогенерация. Таким образом ваш <br/>
           бизнес под нашей надежной защитой. Начните жить <br/>
           стабильно прямо сейчас и становитесь частью наших <br/>
           партнёров.</p>
      </div>
    )
  }
}





